import { useEffect, useState } from "react";
import { Route, useParams } from "react-router-dom";
import { useAuth } from '../../components/AuthContextProvider';
import { MainTemplate2 } from '../../templates/MainTemplate2';
import { Navigate, useLocation } from 'react-router-dom';
import axios from "axios";
import {Button, TableContainer, Table, TableRow, TableCell, TableBody} from "@mui/material";
import { DataElement, MyComponentProps } from "../../types/TypeDefinition";
import * as Routes from "../../ApiRoute";

export const MonitoringResultDetail:React.FC<MyComponentProps> = () => {
    const { loginItem } = useAuth();
    const { id } = useParams();
    const location = useLocation();
    const [isAuthError, setIsAuthError] = useState(false);
    const [tableData, setTableData] = useState<Array<Array<DataElement>> | undefined>();

    useEffect(() => {
        const f = async () => {
            try {
                const response = await axios.get(`${Routes.MonitoringResult.GetElementListById}/${id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${loginItem.token}`,
                        }
                    });
                setTableData(response.data);
            } catch (error: any) {
                console.log(error);
                switch (error.response?.status) {
                    case 401:
                        setIsAuthError(true);
                        break;
                    default:
                        break;
                }
            };
        };
        f();
    }, []);

    const handleClick = async (eventLogId?: string) => {
        //e.preventDefault();
        console.log(eventLogId);
        await fetch(`${Routes.EventLog.GetById}/${eventLogId}`, {
            headers: {
                Authorization: `Bearer ${loginItem.token}`,
            }
        })
            .then(data => data.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.style.display = "none";
                a.href = url;
                a.download = "eventlog_" + eventLogId + ".zip";
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(() => alert("error"));
    };

    const getLinkOrValue = (linkType?: string, linkKey?: string, value?: string) => {

        switch (linkType) {
            case "EventLog":
                if (linkKey !== null) {
                    return <Button onClick={() => handleClick(linkKey)}>{value}</Button>;
                }
                else {
                    return value;
                }
            default:
                return value;
        }
    }

    const NavigateLogin = () => {
        const origin = (location.state as any)?.from?.pathname || '/login';
        return <Navigate to={origin} />;
    }
    
    return (
        isAuthError ? NavigateLogin() :
            <MainTemplate2>
                {(tableData) &&
                    (<TableContainer>
                        <Table size="small">
                            <TableBody>
                                {tableData.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    {row?.map((value, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell component="th" scope="row">
                                                                {value.title}
                                                            </TableCell>
                                                            <TableCell>
                                                                {getLinkOrValue(value.linkType, value.linkKey, value.value)}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>)}
            </MainTemplate2>
    );
};



